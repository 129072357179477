import { z } from "zod";
import { canvasElementBaseSchema } from "./canvas-element";

export enum FrameType {
  default = "default",
  slide = "slide",
}

export const frameSchemaInternal = z.object({
  type: z.string(),
  width: z.number(),
  height: z.number(),
  title: z.string(),
  frameType: z.nativeEnum(FrameType).default(FrameType.default),
  sortIndex: z.number().optional(),
  fill: z.string().optional(),
  visible: z.boolean().optional(),
});

export const frameSchema = canvasElementBaseSchema.merge(frameSchemaInternal);
export type Frame = z.infer<typeof frameSchema>;
