import { z } from "zod";

//TODO:This name is confusing (and misleading), we should refactor it to be named 'anchored lines'!!
export const attachedConnectorSchema = z.object({
  index: z.number().optional(),
  lineId: z.string().nullish(),
  position: z.object({ x: z.number(), y: z.number() }).nullish(),
  offsetX: z.number().nullish(),
  anchorMode: z.string().nullish(),
  dragDisabled: z.boolean().optional(), // legacy - don't use, remove after refactor
});

export const canvasElementBaseSchema = z.object({
  x: z.number(),
  y: z.number(),
  scaleX: z.number().default(1).optional(),
  scaleY: z.number().default(1).optional(),
  attachedConnectors: z.record(z.string(), z.union([attachedConnectorSchema, z.null(), z.undefined()])).optional(),
  hidden: z
    .preprocess((arg) => {
      if (typeof arg == "string") return arg === "true";
      if (typeof arg == "boolean") return arg;
      return false;
    }, z.boolean())
    .default(false)
    .optional(),
  lastModifiedTimestamp: z.number().optional(),
  zIndexLastChangeTime: z.number(),
  groupId: z.string().optional(),
  groupHistory: z.array(z.string()).optional(),
  frameId: z.string().optional(),
  lock: z.number().optional(),
  link: z.string().nullish(),
  flags: z.number().default(0),
  containerId: z.string().nullish(),
});

//TODO: can we unify flags and lock?
export enum Flags {
  None = 0,
  CreatedBySystem = 1, // marks an element that was created with the board, usually from a template
};

export enum LockType {
  None = 0,
  Full = 0x7FFFFFFF
}

export const pointSchema = z.object({
  x: z.number(),
  y: z.number(),
});

export const replicacheConfigSchema = z.object({
  rep: z.any(),
  id: z.string(),
});

export const fireBaseConfigSchema = z.object({
  dbRef: z.any(),
  dep: z.any(),
});

export const syncProviderConfigSchema = z.object({
  replicacheConfig: replicacheConfigSchema.optional(),
  fireBaseConfig: fireBaseConfigSchema.optional(),
  clientId: z.string().optional(),
});

export type CanvasElement = z.infer<typeof canvasElementBaseSchema>;
export type CanvasElementBeforeParse = z.input<typeof canvasElementBaseSchema>;
export type AttachedConnector = z.infer<typeof attachedConnectorSchema>;
export type Point = z.infer<typeof pointSchema>;
export type SyncProviderConfig = z.infer<typeof syncProviderConfigSchema>;
