import { z } from "zod";
import { canvasElementBaseSchema, pointSchema } from "./canvas-element";
import { textEnabledSchema } from "./textEnabled";

export const connectedShapeSchema = z.object({
  id: z.string(),
  type: z.string(),
});

const innerPointSchema = z.object({
  x: z.number(),
  y: z.number(),
  id: z.string(),
});

const connectorTextLabelScheme = textEnabledSchema.merge(
  z.object({
    textLocation: z.number().optional(), // location as [0..1] on the connector line
  })
);

export const connectorSchemaInternal = z.object({
  type: z.literal("connector"),
  connectedShapes: z.array(z.union([connectedShapeSchema, z.null()])), //array of shape ids who are connected as anchors, e.g. [1232347892347, 123234783248],
  points: z.array(pointSchema),
  anchorMode: z.union([z.string(), z.null(), z.undefined()]),
  anchorsMode: z.union([z.array(z.union([z.string(), z.null()])), z.null(), z.undefined()]),
  point1_t: z.number(),
  point2_t: z.number(),
  anchorOrientation: z.union([z.string(), z.null()]),
  anchorIndexes: z.array(z.number()), //holds the indexes of the anchors who are connected to the shape
  activeAnchorIndex: z.union([z.number(), z.null(), z.undefined()]),
  lineType: z.enum(["line", "elbow", "curve"]),
  pointerStyles: z.string().array().length(2),

  rotate: z.number().optional().default(0),
  stroke: z.string(),
  strokeWidth: z.string().or(z.number()),
  dash: z.number().optional(),

  innerPoints: z.array(innerPointSchema).optional(),
});

export const connectorSchema = canvasElementBaseSchema.merge(connectorSchemaInternal).merge(connectorTextLabelScheme);

export type Connector = z.infer<typeof connectorSchema>;
export type ConnectorInput = z.input<typeof connectorSchema>;
export type ConnectedShape = z.infer<typeof connectedShapeSchema>;
export type InnerPointSchema = z.infer<typeof innerPointSchema>;
