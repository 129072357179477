import { z } from "zod";
import { canvasElementBaseSchema } from "./canvas-element";

export type TextAlignment = "left" | "center" | "right";
export enum FontProperties {
  None = 0,
  Bold = 1 << 0,
  Italic = 1 << 1,
  Light = 1 << 2,
  Underline = 1 << 3,
  LineThrough = 1 << 4,
  BoldAndItalic = Bold | Italic,

  StyleBits = Bold | Italic,
  DecorationBits = Underline | LineThrough,
}

export const textEnabledSchema = z.object({
  text: z.string().default(''),
  textColor: z.string().optional(),
  fontSize: z.string().or(z.number()).default(16),
  cursorPosition: z.number().optional(),
  align: z.enum(["left", "center", "right"]),
  fontProps: z.nativeEnum(FontProperties).default(FontProperties.None),
  font: z.string().default("Poppins"),
});

const textEnabledSchemaInternal = textEnabledSchema.merge(canvasElementBaseSchema);

export type TextEnabledElement = z.infer<typeof textEnabledSchemaInternal>;
