import { z } from "zod";
import { canvasElementBaseSchema } from "./canvas-element";

const drawingSchemaInternal = z.object({
  type: z.literal("drawing"),
  id: z.string(),
  scaleX: z.number(),
  scaleY: z.number(),
  rotate: z.number().optional().default(0),
  points: z.array(z.number()),
  stroke: z.string(),
  strokeWidth: z.string().or(z.number()),
});

export const drawingSchema = canvasElementBaseSchema.merge(drawingSchemaInternal);
export type Drawing = z.infer<typeof drawingSchema>;
