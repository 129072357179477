import { z } from "zod";
import { canvasElementBaseSchema } from "./canvas-element";

export const fileSchemaInternal = z.object({
  type: z.union([z.literal("video"), z.literal("image")]),
  fileId: z.union([z.number(), z.string()]).nullish(), //If the file is still being uploaded but presented with local preview, he dosen't have a file id yet
  width: z.number(),
  height: z.number(),
  rotate: z.number().optional().default(0),
  uploadStatus: z.union([z.literal("in-progress"), z.literal("completed")]).nullish(),
  uploadProgess: z.number().nullish(), //upload progress in percentages
});

export const fileSchema = canvasElementBaseSchema.merge(fileSchemaInternal);
export type File = z.infer<typeof fileSchema>;
