import { z } from "zod";

export const boardUserSchema = z.object({
  // required
  isAnonymous: z.boolean(),
  color: z.string(),
  textColor:z.string(),
  name: z.string(),
  // used when logged in
  id: z.string().nullish(),
  photoURL: z.string().nullish(),
  // used when anonymous
  avatar: z.string().nullish(),
  createdAt: z.string().nullish(),
});

export type BoardUser = z.infer<typeof boardUserSchema>;
