import { Action, DispatchFunc } from "frontend/state/actions";
import { Board, BoardState as BoardStateEnum } from "shared/datamodel/schemas/board";
import {
  createBoard as internalCreateBoard,
  deleteBoard as internalDeleteBoard,
  editBoard as internalEditBoard,
  moveBoard as internalMoveBoard,
  duplicateBoard as internalDuplicateBoard,
  fetchBoard,
  getBoards,
  getPasswordProtectedBoard,
} from "../api";
import { BlankCanvasId } from "shared/datamodel/schemas";

export async function reloadBoards(dispatch: DispatchFunc) {
  dispatch({ type: Action.LoadingBoards, payload: null });
  try {
    const boards = await getBoards();
    dispatch({ type: Action.UpdatedBoards, payload: boards });
  } catch (e) {
    dispatch({ type: Action.FailedLoadingBoards, payload: null });
  }
}

export async function deleteBoard(dispatch: any, board: Board) {
  dispatch({ type: Action.DeleteBoard, payload: board.documentId });
  try {
    await internalDeleteBoard(board.documentId);
  } catch {
    dispatch({ type: Action.FailedDeleteBoard, payload: board });
  }
}

export async function duplicateBoard(dispatch: any, board: Board) {
  const { board: newBoard, contents } = await internalDuplicateBoard(board.documentId);
  dispatch && dispatch({ type: Action.CreatedBoard, payload: newBoard });
  return { documentId: newBoard.documentId, contents };
}

export async function editBoard(dispatch: any, board: Board, oldBoard: Board) {
  dispatch({ type: Action.EditedBoard, payload: board });
  try {
    const res = await internalEditBoard(board);
    return res;
  } catch (e: any) {
    dispatch({ type: Action.FailedEditBoard, payload: oldBoard });
  }
}

export async function moveBoard(dispatch: any, board: Board, oldBoard: Board) {
  dispatch({ type: Action.EditedBoard, payload: board });
  try {
    const res = await internalMoveBoard(board);
    return res;
  } catch (e: any) {
    dispatch({ type: Action.FailedEditBoard, payload: oldBoard });
  }
}

export async function createBoard(
  name: string,
  state: BoardStateEnum | null,
  createdFromTemplateId: string | null,
  projectId: number | null,
  teamId: number | null,
  dispatch?: any
): Promise<Board> {
  createdFromTemplateId = createdFromTemplateId !== BlankCanvasId ? createdFromTemplateId : null;
  const board = await internalCreateBoard(
    name,
    createdFromTemplateId,
    state ?? BoardStateEnum.default,
    projectId,
    teamId
  );
  console.log("internalCreateBoard", { board, state, createdFromTemplateId });
  dispatch && dispatch({ type: Action.CreatedBoard, payload: board });
  return board;
}

export async function loadBoard(dispatch: DispatchFunc, documentId: string, password?: string) {
  dispatch({ type: Action.LoadingSpecificBoard, payload: documentId });
  let data : {board: Board, templateElements: any, authToken?: string};
  if (password) {
    data = await getPasswordProtectedBoard(documentId, password);
  } else {
    data =  await fetchBoard(documentId);
  }
  dispatch({ type: Action.LoadedSpecificBoard, payload: data.board });
  return data;
}
