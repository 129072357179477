import { z } from "zod";

export const signupFormSchema = z.object({
  name: z.string().min(1),
  password: z.string().min(8),
  email: z.string().email(),
  accountName: z.string().min(1),
  approvedTos: z.literal(true),
});

export type SignupFormValues = z.infer<typeof signupFormSchema>;
